import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from "axios";
import { IonicVue } from '@ionic/vue';
import {convertMilesPerHourToMetersPerSecond, LanderResults} from "./lander-results";
import { convertMilesToMeters, convertMilesPerSecondToMetersPerSecond, convertFeetToMeters } from "./lander-results";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { createStore, useStore, Store } from 'vuex'

interface customWindow extends Window {
  loader?: any;
}

declare const window: customWindow;

interface storeData {
  automationFuelRate: "",
  apiKey: "",
  miles: 0,
  meters: 0,
  feet: 0,
  velocity: 0,
  velocity_mps: 0,
  remainingFuel: 0,
  seriesAltitude: [{name:"altitude","data":[]}],
  seriesAltitudeMeters: [{name:"altitudeMeters","data":[]}],
  seriesVelocity: [{name:"velocity","data":[]}],
  seriesVelocity_mps: [{name:"velocity_mps","data":[]}],
  loggerData:"",
  allScores: Array<LanderResults>,
  topScores: Array<LanderResults>
}

// Create a new store instance.
const store = createStore({
  state():storeData {
    return {
      automationFuelRate: "",
      apiKey: "",
      miles: 0,
      meters: 0,
      feet: 0,
      velocity: 0,
      velocity_mps: 0,
      remainingFuel: 0,
      seriesAltitude: [{name:"altitude","data":[]}],
      seriesAltitudeMeters: [{name:"altitudeMeters","data":[]}],
      seriesVelocity: [{name:"velocity","data":[]}],
      seriesVelocity_mps: [{name:"velocity_mps","data":[]}],
      loggerData:"",
      allScores: [],
      topScores: []
    }
  },
  getters: {
    apiKey(state:storeData) {
      return state.apiKey;
    }
  },
  actions: {
    codeResult(context) {
      const code = window.localStorage.getItem('code');
      function updateLog(message:string) {
        console.log(`updating log with ${message}`);
        store.commit("setLoggerData",message);
      }
      function getCodeFunction(obj:string | any) {
        if (obj) {
          return Function('data', 'logger', obj);
        } else {
          return Function('data', 'logger', 'return -1');
        }
      }
      try {
        const res = getCodeFunction(code);
        const data = {
          miles: parseFloat(store.state.miles.toString()),
          feet: parseFloat(store.state.feet.toString()),
          meters: parseFloat(store.state.meters.toString()),
          velocity_mph: parseFloat(store.state.velocity.toString()),
          velocity_mps: parseFloat(store.state.velocity_mps.toString()),
          remainingFuel: parseFloat(store.state.remainingFuel.toString()),
          remainingFuel_kg: parseFloat((store.state.remainingFuel * 0.453592).toString())
        }
        const v: number = res(data, updateLog);
        context.commit('setNewAutomationFuelRate', isNaN(v) ? "" : v.toString());
      } catch (e) {
        context.commit("setLoggerData", e);
        context.commit("setLoggerData","aborting simulation - please refresh page and fix code.")
        context.commit('setNewAutomationFuelRate',  "");
      }
    },
    obtainConfig(context) {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      };
      axios.get(`https://landerapidev2.tioth.com/history/config`, {headers})
        // eslint-disable-next-line no-unused-vars
        .then(async (x) => {
          store.commit("setKey", x.data.k);
          await store.dispatch("obtainHistory");
        })
        .catch((e) => {
          console.warn(`warning, error: ${e}`);
        });
    },
    obtainHistory(context) {
      const headers = {
        'Content-Type': 'application/json',
        'X-Api-Key': store.state.apiKey,
        'Access-Control-Allow-Origin': '*'
      };
      axios.get(`https://landerapidev2.tioth.com/history/query`,{headers})
        // eslint-disable-next-line no-unused-vars
        .then((x) => {
          const results:Array<LanderResults> = [];
          x.data.map( (ele:any) => {
            const lr = new LanderResults(ele["lander_name"], ele["mode"], ele["velocity"], ele["remaining_fuel"], ele["crater_depth"], ele["created_at"]);
            results.push(lr);
          })
          context.commit("setScores", results);
        })
        .catch((e) => {
          console.warn(`warning, error: ${e}`);
        });
    },
  },
  mutations: {
    setNewAutomationFuelRate (state: any, value: number) {
      state.automationFuelRate = value;
    },
    setKey (state: any, value: string) {
      state.apiKey = value;
    },
    setMiles (state: any, value: number) {
      state.miles = value;
    },
    setMeters (state: any, value: number) {
      state.meters = convertMilesToMeters(value);
    },
    setFeet (state: any, value: number) {
      state.feet = value;
    },
    setVelocity (state: any, value: number) {
      state.velocity = value;
    },
    setVelocityMps (state: any, value: number) {
      state.velocity_mps = convertMilesPerSecondToMetersPerSecond(value);
    },
    setRemainingFuel(state: any, value: number) {
      state.remainingFuel = value;
    },
    clearAltitudeData( state) {
      state.seriesAltitude =  [{name:"altitude","data":[]}];
      state.seriesAltitudeMeters = [{name:"altitudeMeters","data":[]}];
    },
    clearVelocityData( state) {
      state.seriesVelocity = [{name:"velocity","data":[]}];
      state.seriesVelocity_mps = [{name:"velocity_mps","data":[]}];
    },
    setAltitudeData( state: any, value: number) {
      const series1 = state.seriesAltitude[0]
      const series1a = state.seriesAltitudeMeters[0]
      // series1.data.push([0,120]);
      // series1a.data.push([0,convertMilesToMeters(120).toFixed(0)]);
      if (value) {
        let x = series1.data.length;
        x = (x * 10) + 10;
        series1.data.push([x,value.toFixed(0)]);
        series1a.data.push([x,convertMilesToMeters(value).toFixed(0)]);
      }
      // state.seriesAltitude = new Array(series1);
      // state.seriesAltitudeMeters = new Array(series1a);
    },
    setVelocityData( state: any, value: number) {
      const vm = convertMilesPerHourToMetersPerSecond(value)
      console.log(`setting velocity data: ${vm}`);
      console.log(`data before: ${JSON.stringify(state.seriesVelocity_mps)}`)
      const series1 = state.seriesVelocity[0]
      const series1a = state.seriesVelocity_mps[0]
      // series1.data.push([0,3600]);
      // series1a.data.push([0,Math.floor(convertMilesPerSecondToMetersPerSecond(1))]);
      if (value) {
        let x = series1.data.length;
        x = (x * 10) + 10;
        series1.data.push([x,(value).toFixed(2)]);
        series1a.data.push([x,Math.floor(vm)]);
      }
      console.log(`data after: ${JSON.stringify(state.seriesVelocity_mps)}`)
      // state.seriesVelocity = new Array(series1);
      // state.seriesVelocity_mps = new Array(series1a);
    },
    setScores(state: any, value: Array<LanderResults>) {
      state.allScores = value;
      state.topScores = [];
      value.map(ele=> {
          state.topScores.push(ele)
      })
    },
    setLoggerData(state: any, value: string) {
      console.log(`setting new logger data: ${JSON.stringify(value)}`);
        state.loggerData = state.loggerData + value + "\n";
    },
    clearLoggerData(state: any) {
      console.log(`clearning logger data`);
      state.loggerData = "";
    }
  }
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store);

router.isReady().then(async () => {
  app.mount('#app');
  await store.dispatch("obtainConfig");
});

document.addEventListener("updatelexstate", async function(data: any) {
  if (data.detail.state.sessionAttributes.qnabotcontext) {
    const state = JSON.parse(data.detail.state.sessionAttributes.qnabotcontext);
    if (state) {
      console.log(`currentLanderState ${JSON.stringify(state)} `);
      if (state.elapsedTime == 0) {
        store.commit('clearAltitudeData');
        store.commit('clearVelocityData');
      }
      if (state.altitudeM) {
        store.commit('setMiles', state.altitudeM.toFixed(2));
        store.commit('setMeters', state.altitudeM.toFixed(2));
        store.commit('setFeet', (5280 * state.altitudeM).toFixed(0));
        store.commit('setVelocity', (state.velocity).toFixed(2));
        store.commit('setRemainingFuel', state.remainingFuel);
        store.commit('setAltitudeData', state.altitudeM);
        store.commit('setVelocityData', state.velocity);
      }
    }
    if (state.finalMessage && state.finalMessage.length>0) {
      store.dispatch("obtainHistory");
    } else if (state.useautomation && state.useautomation === "yes") {
        await store.dispatch("codeResult");
        const fuelRate = store.state.automationFuelRate.toString();
        if (fuelRate !== "") {
          const codeEvent = new CustomEvent('postautomationresult', {detail: fuelRate.toString()});
          document.dispatchEvent(codeEvent);
        }
        if (state.automationlogmessage && state.automationlogmessage.length>0) {
          store.commit("setLoggerData",state.automationlogmessage);
        }
    }
    if (data.detail.state.sessionAttributes.qnabot_qid==="automation.prompt.1") {
      console.log(`matched on prompt`);
      await store.commit("clearLoggerData");
    } else {
      console.log(`session attributes ${JSON.stringify(data.detail.state.sessionAttributes)} `);
    }
  }
})

document.addEventListener("enableautomationcode", function(evt: any) {
  window.loader.api.setSessionAttribute("enableautomationcode",evt.detail.toString());
})

document.addEventListener( "postautomationresult", async function(evt:any) {
  setTimeout(()=>{
    window.loader.api.postText(evt.detail.toString(),window.loader.api.MESSAGE_TYPE_HUMAN);
    },1000)
})

